import React from 'react'

const Logo = (props) => {
  return (
    <a href="//www.frederickoller.ch" target="_blank">
      <svg
        viewBox="0 0 231 187"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        {...props}
      >
        <g fill="#504a4a">
          <path d="M93.978 104.615l11.497-10.186 10.539 9.178v71.546c0 3.31-.958 5.967-2.874 7.97a9.227 9.227 0 01-1.62 1.349c-1.791 1.394-4.067 2.091-6.829 2.091-3.309 0-5.922-1.001-7.838-3.005-1.917-2.003-2.875-4.659-2.875-7.969v-70.974zM171.248 36.118l37.438-33.244C210.602.958 212.867 0 215.48 0c2.613 0 4.877 1.002 6.793 3.005 1.916 2.003 2.874 4.224 2.874 6.663 0 2.613-1.132 5.052-3.396 7.316l-84.398 74.207 89.101 77.866c2.439 2.264 3.658 4.79 3.658 7.577 0 2.439-1.002 4.66-3.005 6.663s-4.311 3.005-6.924 3.005c-2.265 0-4.616-1.045-7.055-3.136l-49.28-43.087.005-.006-3.279-2.856-44.56-38.96v.151l-7.579-6.601 7.579-6.715v.071l35.985-31.953 19.268-17.071-.019-.021zM93.091 100.162H15.155c-3.136 0-5.574-.784-7.316-2.351-1.742-1.568-2.613-3.746-2.613-6.533 0-2.961.871-5.226 2.613-6.793 1.742-1.568 4.18-2.352 7.316-2.352h78.823V18.639H9.929c-3.135 0-5.574-.784-7.316-2.352C.871 14.72 0 12.542 0 9.755c0-2.961.871-5.226 2.613-6.794C4.355 1.394 6.794.61 9.929.61h95.111c3.31 0 5.966.958 7.969 2.874 2.003 1.916 3.005 4.442 3.005 7.577v68.792l-22.923 20.309z" />
        </g>
      </svg>
    </a>
  )
}

Logo.defaultProps = {
  width: '30',
  height: '30'
}

export default Logo
